import ReactDom from "react-dom";
import * as serviceWorker from './serviceWorker';
import Scan from "./components/scan";


ReactDom.render((
    <div className="main">
       <div className="logo"><img src="https://ljubljana.tech/images/logo-badge.svg" style={{width: 80, height: 50, position: "absolute", top: 20, left: "50%", transform: "translate3d(-50%,0,0)"}} /></div>
      <Scan scanRate={250} covid19={true} upnqr={true}/>
      <div className="logob"><img src="https://ljubljana.tech/images/logo.svg" style={{width: 160, height: 50, position: "absolute", bottom: 20, left: "50%", transform: "translate3d(-50%,0,0)"}} /></div>
    </div>
), document.getElementById("app"));

serviceWorker.register();
